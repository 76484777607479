import React from 'react';
import Img from 'img';
import { Link } from 'gatsby';
import Headline from 'headline';
import Container from 'container';
import s from './description.module.scss';

// Main Component
export default ({
  main_title,
  paragraph,
  link,
  link_text,
  articles,
  dark,
  grey
}) => (
  <Container className={s.section} grey={grey}>
    <div className={s.block}>
      <Headline
        h2
        primary
        className={`${s.headline} ${s.text}`}
        html={main_title}
      />
      <p dangerouslySetInnerHTML={{ __html: paragraph }} className={s.text} />
      <div>
        <Link dangerouslySetInnerHTML={{ __html: link_text }} to={link} />
      </div>
    </div>
    <div className={s.wrapper}>
      {articles
        && articles.map(({ title, text, image }) => console.log(articles.length) || (
          <div className={`${s.articleContainer} ${articles.length % 2 ? '' : s.even}`}>
            <div className={s.imgWrapper}>
              <Img src={image.title} alt={image.title} className={s.img} />
            </div>
            <h6
              className={`${s.cardTitle} ${s.text}`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p dangerouslySetInnerHTML={{ __html: text }} className={s.text} />
          </div>
        ))}
    </div>
  </Container>
);
