import React from 'react';
import TextSection from './text_section';
import Circles from './circles';

export default function circleImagesWithName({ section_title, section_text, circles }) {
  return (
    <>
      <section>
        <TextSection title={section_title} text={section_text} />
        {/* <Circles circles={circles} /> */}
      </section>
    </>
  );
}
