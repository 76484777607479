import React from 'react';
import Img from 'img';
import Icon from 'icon';
import Headline from 'headline';
import Container from 'container';
import Form from './form';
import s from './formsection.module.scss';
import dataES from './data-es';

// Main Component
export default function FormSection({
  image,
  headline,
  subtitle,
 
}) {
  const cards=dataES.cards;
  return (
    <Container className={`${s.section} ${cards && s.withCards}`} id="form">
      {image && (
      <Img
        src={image && image.title}
        alt={image && image.title}
        className={s.img}
      />
      )}
      <div className={s.overlay}>
        {cards ? (
          <>
            <div className={s.block1} />
            <div className={s.block2} />
          </>
        ) : null}
      </div>
      <div className={s.content}>
        <Headline html='Contáctenos' h2 className={s.headline} />
        <p
         
          className={s.subtitle}
        > ¡Obtenga una cotización gratuita o simplemente asesoramiento!</p>
        <div className={s.flexed}>
          <Form />
          {cards ? (
            <div className={s.cards}>
              {cards.map(({ title, icon, paragraphs }) => (
                <div className={s.card} key={title}>
                  <div className={s.cardTitleWrapper}>
                    <Icon icon={icon} />
                    <p className={s.cardTitle} dangerouslySetInnerHTML={{ __html: title }} />
                  </div>
                  <div className={s.contentWrapper}>
                    {paragraphs.map(el => (
                      <p dangerouslySetInnerHTML={{ __html: el.text }} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </Container>
  );
}
