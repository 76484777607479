import React from 'react';
import { Helmet } from 'react-helmet';
import PageGenerator from 'components/page-generator';
import 'styles/project.scss';

function Head({ title, description }) {
  return title || description ? (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
    </Helmet>
  ) : null;
}

export default function PageTemplate(props) {
  const { acfData, slug, fetchSlug, meta, acfKeys } = props.pageContext;
  return (
    <>
      <Head {...meta} />
      <main>
        <PageGenerator
          acfData={acfData}
          slug={slug}
          fetchSlug={fetchSlug}
          acfKeys={acfKeys}
        />
      </main>
    </>
  );
}
