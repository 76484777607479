import React from 'react';
import Container from 'container';
import Img from 'img';

import s from './circles.module.scss';

export default function Circles({ circles }) {
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        {circles.map((el, i) => (
          <div key={i} className={s.circle}>
            <Img className={s.image} src={el.image.title} alt={el.image.title} />
            <p className={s.text}>{el.image_text}</p>
          </div>
        ))}
      </div>
    </Container>
  );
}
