import React, { useState } from 'react';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import Button from 'button';
import validate from './validate';
import s from './form.module.scss';

export default function Form({ variation }) {
  const firstName = useFormInput('');
  const lastName = useFormInput('');
  const email = useFormInput('');
  const msg = useFormInput('');
  const phone = useFormInput('');
  const [isChecked, setIsChecked] = useState(false);
  const firstNameErrors = useErrors({ firstName: firstName.state }, validate);
  const lastNameErrors = useErrors({ lastName: lastName.state }, validate);
  const emailErrors = useErrors({ email: email.state }, validate);
  const msgErrors = useErrors({ msg: msg.state }, validate);
  const phoneErrors = useErrors({ phone: phone.state }, validate);
  const submitReady = useSubmitReady(
    {
      firstName: firstName.state,
      lastName: lastName.state,
      email: email.state,
      msg: msg.state,
    },
    validate
  );

  function handleSubmit() {
    if (window && window.gtag) {
      window.gtag('send', 'event', {
        eventCategory: 'Form',
        eventAction: 'Submit',
        eventLabel: window.location.href,
        eventValue: 1
      });
    }

    const formBody = {
      firstName: firstName.state.value,
      lastName: lastName.state.value,
      email: email.state.value,
      msg: msg.state.value,
      phone: phone.state.value,
      optIn: isChecked,
    };
    fetch('https://us-central1-fortune-settlements.cloudfunctions.net/sendSubmissionToZapier', { headers: { 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(formBody) }).then(() => {
      window.location.href = '/thankyou/';
    }).catch(err => console.log(err));
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <form className={`${s.form} ${variation && s.variation}`} autoComplete="on">
      {/* for variation (variation class) */}
      <div className={`${s.inputs}`}>
        <div className={s.flexed}>
          <div className={`${s.block} ${s.margin}`}>
            <label htmlFor="firstName">First name:</label>
            <p>Your first name</p>
            <input
              value={firstName.state.value}
              {...firstName.events}
              type="text"
              name="firstName"
              className={`${firstNameErrors.firstName && s.error}`}
            />
          </div>
          <div className={s.block}>
            <label htmlFor="lastName">Last name:</label>
            <p>Your last name</p>
            <input
              value={lastName.state.value}
              {...lastName.events}
              type="text"
              name="lastName"
              className={`${lastNameErrors.lastName && s.error}`}
            />
          </div>
        </div>
        <div className={s.flexed}>
          <div className={`${s.block} ${s.margin}`}>
            <label htmlFor="phone">Telephone:</label>
            <p>Where can we reach you</p>
            <input
              value={phone.state.value}
              {...phone.events}
              type="tel"
              name="phone"
              className={`${phoneErrors.phone && s.error}`}
            />
          </div>
          <div className={s.block}>
            <label htmlFor="email">E-mail:</label>
            <p>Where can we reach you</p>
            <input
              value={email.state.value}
              {...email.events}
              type="text"
              name="email"
              className={`${emailErrors.email && s.error}`}
            />
          </div>
        </div>
      </div>
      <div className={s.textArea}>
        <label htmlFor="msg">Your message:</label>
        <p>Describe your situation</p>
        <textarea
          name="msg"
          id="msg"
          cols="40"
          rows="6"
          value={msg.state.value}
          {...msg.events}
          className={`${msgErrors.msg && s.error}`}
        />
        <div className={s.flexH}>
          <div className={s.wrapper}>
            <label className={s.container}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className={s.checkmark} />
            </label>
          </div>
          <div className={s.opInBody}>
            <p>
              <span>
                "Privacy &amp; Opt-In: By clicking the “NEXT” button, you certify that you have provided your legal name and your own phone number, you agree to the <a href="/privacy-policy/" target="_blank" rel="noopener">"Terms &amp; Conditions and Privacy Policy" </a> and authorize FORTUNE to contact you. By clicking the “NEXT” button and submitting this form, I affirm that I have read and agree to this Site's Terms & Conditions and Privacy Policy. I consent to receive SMS text messages to my cell number provided above for notifications, alerts, and general communication purposes including promotions from FORTUNE. I understand that I am not required to provide my consent as a condition of purchasing any products or services. I understand that I can opt-out of receiving text messages at any time by responding with STOP. I can reply with HELP to get help. Message and data rates may apply depending on your mobile carrier. Message frequency may vary."
              </span>
            </p>
          </div>
        </div>
        <Button disabled={!submitReady} onClick={() => handleSubmit()}>
          Submit
        </Button>
      </div>
    </form>
  );
}
