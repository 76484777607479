import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'shared/headline';
import s from './moreteam.module.scss';

// Main Component
export default ({ headline, teamMember }) => (
  <Container className={s.section}>
    <Headline html={headline} className={s.headline} />
    <div className={s.flexed}>
      {teamMember &&
        teamMember.map(({ image, name, jobTitle }) => (
          <div className={s.wrapper}>
            <div className={s.imgWrapper}>
              <Img src={image.title} alt={image} className={s.img} />
            </div>
            <div className={s.overlay} />
            <div className={s.content}>
              <h4 dangerouslySetInnerHTML={{ __html: name }} />
              <p dangerouslySetInnerHTML={{ __html: jobTitle }} />
            </div>
          </div>
        ))}
    </div>
  </Container>
);
