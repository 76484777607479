import React from 'react';
import Icon from 'icon';
import Container from 'container';
import s from './featured.module.scss';

export default ({ list, ...props }) => (
  <Container className={s.section}>
    <div className={s.wrapper}>
      <ul className={s.listWrapper}>
        {list.map(({ icon, title, text }, i) => (
          <li className={s.featureContainer} key={`${title}-${i}`}>
            <span className={s.icon}>
              <Icon icon={icon} />
            </span>
            <div className={s.textWrapper}>
              <span
                className={s.title}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <span dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  </Container>
);
