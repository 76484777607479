import React, { useState } from 'react';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './slider_hero.module.scss';
import Swiper from 'react-id-swiper/lib/ReactIdSwiper.full';
import 'swiper/dist/css/swiper.css';
import { Navigation } from 'swiper/dist/js/swiper.esm';

// Helper Slide Component
function Slide({ title, subtitle, button1, button2, image, scroll }) {
  return (
    <div className={s.slideContainer}>
      <div className={s.image}>
        <Img src={image.title} alt={image} className={s.img} />
      </div>
      <div className={s.overlay} />
      <div className="container">
        <div className={s.content}>
          <Headline center white html={title} />
          <p dangerouslySetInnerHTML={{ __html: subtitle }} />
          <div className={s.buttons}>
            {button1.text !== '' && <Button scroll={button1.scroll} href={button1.href} html={button1.text} />}
            {button2.text !== '' && <Button scroll={button2.scroll} href={button2.href} outlined html={button2.text} />}
          </div>
        </div>
      </div>
    </div>
  );
}

// Main Component
export default function SliderHero({ slides }) {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
 
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      shouldSwiperUpdate: false,
      getSwiper: updateSwiper,
      modules:[Navigation],
      effect: 'fade',
  }


  return (
    <div className="swiper-container s1">
      <Swiper className="swiper-wrapper" {...params} >
        {slides.map((slide, i) => (
          <div className="swiper-slide" key={i}>
            <Slide {...slide} />
          </div>
        ))}
      </Swiper>
      {slides.length !== 1 && (
        <>
          <div onClick={goPrev} className="swiper-button-prev swiper-button-white" />
          <div onClick={goNext} className="swiper-button-next swiper-button-white" />
        </>
      )}
    </div>
  );
}
