import React from 'react';
import Container from 'container';
import Button from 'button';
import Headline from 'headline';
import s from './contact.module.scss';

// Main Component
export default ({ title, subtitle, button1, button2, dark, ...props }) => (
  <Container className={`${s.section}`}>
    <div className={s.wrapper}>
      <div className={`${s.leftside}`}>
        <Headline h1 white className={`${s.title}`} html={title} />
        <p className={`${s.subtitle}`} dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
      <div className={`${s.rightside}`}>
        <Button className={s.button} native href={button1.href} html={button1.text} />
        <Button white className={s.button} native outlined href={button2.href} html={button2.text} />
      </div>
    </div>
  </Container>
);
