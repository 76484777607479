export default {
  cards: [
    {
      title: 'Llámenos',
      icon: 'phone',
      paragraphs: [{
        text: 'Llámenos al <a href="tel:+18338884472">(833) 888-4472</a>'
      }
      ]
    },
    {
      title: 'Envíenos un correo',
      icon: 'envelope',
      paragraphs: [{
        text: 'Envíenos un correo <a href="mailto:info@fortunesettlements.com">info@fortunesettlements.com</a>'
      }
      ]
    },
    {
      title: 'Visítenos',
      icon: 'map-marker',
      paragraphs: [{
        text: '8000 Governor’s Square Blvd.'
      },
      {
        text: 'Miami Lakes, FL 33016'
      }
      ]
    }
  ]
};
