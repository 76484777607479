import React from 'react';
import Container from 'container';
import Headline from 'shared/headline';
import Icon from 'icon';
import s from './dyk.module.scss';

export default ({ list, title }) => (
  <Container className={`${s.section}`}>
    <Headline hrml={title} />
    <ul className={`${s.flex}`}>
      {list && list.map(({ icon, subtitle, text }, el) => (
        <li className={`${s.half} ${s.listItem}`} key={subtitle}>
          <Icon icon={icon} />
          <div className={s.textWrapper}>
            <div className={`${s.listTitle}`} dangerouslySetInnerHTML={{ __html: subtitle }} />
            <p className={`${s.listText}`} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </li>
      ))}
    </ul>
  </Container>
);
