import React from 'react';
import getTag from 'utils/get-tag';
import Icon from 'icon';
import Img from 'img';
import Container from 'container';
import s from './testimonials.module.scss';

// Main Component
export default function Testimonials({ list, ...props }) {
  return (
    <Container className={s.section}>
      {list.map(({ review, name, job, image }) => {
        const ReviewTag = getTag(review);
        const JobTag = getTag(job);
        return (
          <div className={s.reviewContainer}>
            <div className={s.card}>
              <ReviewTag
                dangerouslySetInnerHTML={{ __html: review }}
                className={s.review}
              />
              <div className={s.triangle} />
            </div>
            <div className={s.client}>
              <div className={s.imgWrapper}>
                <Img className={s.img} src={image.title} alt={image.title} />
              </div>
              <h4
                dangerouslySetInnerHTML={{ __html: name }}
                className={s.name}
              />
              <JobTag
                dangerouslySetInnerHTML={{ __html: job }}
                className={s.job}
              />
            </div>
          </div>
        );
      })}
    </Container>
  );
}
